@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;500;700&display=swap');



#root{
  width: 100vw;
}

/* Global Styles */
body {
  font-family: 'Roboto Mono','Arial', sans-serif;
  margin: 0;
  padding: 0;
  /*background-color: #f7f7f7;
  background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(97,186,255,1) 0%, rgba(166,239,253,1) 90.1% );
  background-color: #80d0ff;
  background-image: linear-gradient(160deg, #80d0ff 0%, #80D0C7 100%);
  background-color: #80d0ff;
  background-image: linear-gradient(160deg, #80d0ff 0%, #80D0C7 100%);*/
  background-color: #80d0ff;
  background-image: linear-gradient(160deg, #80d0ff 0%, #80D0C7 33%, #a5f9ff 66%, #ffffff 100%);

  
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /*background-color: rgb(148, 225, 255);*/
  width: 90%;
  margin-left: 5%;
  height: 45px;
}



.navbar .aboutbutton{
  margin-left: 20px;
  position: fixed;
  top: 15px;
  left: 10%;
  z-index: 1000;
}

.navbar .logo {
  font-size: 24px;
  font-family: 'Roboto Mono';
  text-decoration: none;
  color: black;
  text-align: center;
  width: 100%;
  text-transform: none;
}

.navbar .buy-now {
  background-color: #4CAF50;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  position: fixed;
  top: 15px;
  right: 10%;
}

/* Intro Section */
.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.introText {
  flex: 1;
}

.hero-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.primary-button {
  background: #4CAF50;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
}

.secondary-button {
  background: transparent;
  border: 2px solid #4CAF50;
  color: #4CAF50;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
}

.product-showcase {
  flex: 1;
  display: flex;
  justify-content: center;
  
}

.cyclingPics{
  height: 200px;
  width: 300px; 
  margin-left: 0%;

}

/* Product Display */
.product-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 100px;
  justify-content: space-evenly;
  width: 50%;
  margin-left: 0%;
}

.product-display .product {
  width: 45%; /* considering 20px gap */
  align-items: center;
  display: flex;
  flex-direction: column;
  
  

}




.product .textAbove{
  color: white;
}

/*Keep in case I want to put text on top of image*/
/*
.product .textOverlay {
  position:inherit;
  transform: translateY(-350px);
  left: 50%; /* Adjust as needed 
  color: white; /* or any color 
  font-size: 30px; /* Adjust as needed 
  /* Add more styling as required 
}
*/

/* Description */
.description {
  display: flex;
  justify-self: center;
  text-align: center;
  font-size: 24pt;
  padding: 0 20% 5% 20%;
  width: 60%;
}

/* Meet Max Section */
.meet-max {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  text-align: center;
}

.meet-max button{
  background-color: #4CAF50;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  padding: 1%;
  font-size: 24px;
  height: 10%;
}

.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 10% 0 10%;
}

.about h1{
  text-align: center;
}

.about p{
  font-size: 26px;
}

.about img{
  width: 30%;

}


.singleItems{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: end;
  gap: 5%;
  
}

.singleItems .buySection{
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content:space-between;
}

.singleItems .buySection h1{
  margin: 0;
}

.singleItems .buySection .productDescription{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:40%;
}

.singleItems .buySection .imgSection{
  width: 100%;
  /*height: 30%;*/
  
}

.singleItems .buySection .imgSection2{
  width: 65%;
  padding-left: 17.5%;
}

.singleItems .buySection img{
  width: 100%;
}

.singleItems .buySection .buyButton{
  height: 30%;
  margin-top: 5%;

}

.comboBuyButton{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4%;
}

.footer{
  text-align: center;
  width: 100%;
  /*background-color: #80d0ff;*/
  /*background-image: linear-gradient(160deg, #80d0ff 0%, #80D0C7 33%, #a5f9ff 66%, #ffffff 100%);*/
  margin-top: 20px;
}

.footer .footerLogo{
  margin: 0;
  padding: 0% 0 2% 0;
}

.footer .footerButtons{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly ;
  
}

.footer .footerButton{
  font-size: 24px;
  padding: 0 10% 0 10%;
  text-decoration: none;
  width: 200px;
}

.footer p{
  font-size: 14px;
}

.PPolicy{
  margin-left: 20%;
  width: 60%;
}

/* Responsive for mobile devices */
@media (max-width: 1100px) {

/* Navbar */
.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 0%;
  height: 45px;
  padding: 0;
  margin-top: 10px;
}



.navbar .aboutbutton{
  margin-left: 0px;
  position: absolute;
  top: 20px;
  left: 5%;
  z-index: 1000;
  padding: 0;
  
}

.navbar .logo {
  font-size: 24px;
  font-weight: 100;
  text-decoration: none;
  color: black;
  text-align: center;
  
}

.navbar .buy-now {
  background-color: #4CAF50;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 0px;
  position: absolute;
  top: 10px;
  right: 3%;
  z-index: 1000;
  padding: 10px;
}



  .intro {
      width: 100%;
      display: flex;
      justify-content: center;
  }



  .intro .introText{
    text-align: left;
    width: 80%;
  }


  .intro h1{
    font-size:50pt;
    text-align: left;
    margin: 0;
  }

  .intro .introText .slogan{
    font-size: 16pt;
  }

  .frontpageimage{
    height: 100px;
    border-radius: 10px;
  }

  .product-display .product {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 5px;
  }

  /* Product Display */
.product-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 100px;
  justify-content: space-evenly;
  width: 80%;
  margin-left: 10%;
}

.product-display .product {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  
  

}

.product-display .product1 img{
  
  height: 500px;
  border-radius: 10px;
}


.product .textAbove{
  color: white;
  margin: 0;
}

.description{
  font-size: 18pt;
}

  .about {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 10% 0 10%;
  }
  
  .about h1{
    text-align: center;
  }
  
  .about p{
    font-size: 14pt;
  }
  
  .about img{
    width: 40%;
  
  }

  .PPolicy{
    font-size: 14pt;
    width: 80%;
    margin-left: 10%;
  }

  .singleItems{
    display:block;
    text-align: center;
    justify-content: center;
    
  }

  .singleItems .buySection{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content:space-between;
  }
  
  .singleItems .buySection h1{
    margin: 0;
  }
  
  .singleItems .buySection .productDescription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:50%;
  }
  
  .singleItems .buySection .imgSection{
    width: 100%;
    /*height: 30%;*/
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .singleItems .buySection img{
    width: 100%;
  }
  
  .singleItems .buySection .buyButton{
    height: 30%;
    margin-top: 5%;
  }
  
  .comboBuyButton{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8%;
  }


}/*End Mobile Design*/

@media (max-width: 768px) {
    .intro {
        flex-direction: column;
        text-align: center;
    }

    .hero-actions {
        flex-direction: column;
        width: 100%;
    }

    .primary-button,
    .secondary-button {
        width: 100%;
    }
}

.product-showcase img {
    max-width: 300px;  /* Adjust this value to your preferred size */
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 768px) {
    .product-showcase img {
        max-width: 300px;  /* Slightly smaller on mobile */
    }
}

